import React, { Fragment, useState } from "react";

import { useIdContext } from "@id/context";

import { useLeadActions } from "@api/leads";

import type { IPendingOptionsProps } from "@id/types";

import { Typography } from "~/components/Typography";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { makeStyles } from "@material-ui/core/styles";
import { useFeedbackContext } from "~/components/Feedback";

const useStyles = makeStyles(() => ({
    button: {
        padding: 0,
    },
    menu: {
        borderRadius: 8,
        padding: 0,
    },
}));

export const PendingOptions: React.FC<IPendingOptionsProps> = ({ leadId }) => {
    const { handleOpenFeedback } = useFeedbackContext();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const styles = useStyles();

    const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (
        cb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    ) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        if (cb) cb(e);
        setAnchorEl(null);
    };
    const { requestIdVerification } = useLeadActions(leadId);
    const handleSubmit = async () => {
        try {
            leadId && await requestIdVerification();
            handleOpenFeedback({ message: "ID verification request sent successfully!", severity: "info" });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Fragment>
            <IconButton
                className={styles.button}
                onClick={handleMenuClick}
            >
                <MoreHorizIcon />
            </IconButton>

            <Menu
                keepMounted
                classes={{
                    paper: styles.menu,
                }}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose()}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={handleSubmit}>
                    <Typography variant="body1">
                        Resend Request
                    </Typography>
                </MenuItem>
            </Menu>
        </Fragment>
    );
};