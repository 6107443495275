import React from "react";

import truncate from "lodash/truncate";


import { PendingOptions } from "./PendingOptions";

import { ITableColumn } from "~/components/Table";

import type { IPendingPhone } from "@id/types";

import { PendingLabel } from "./PendingLabel";

import { urlColumnStyles } from "@id/styles";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import { ClipboardCopyIcon } from "~/components/ClipboardCopy";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        gap: 12,
    },
    phoneNumber: {
        minWidth: 128,
    },
}));

const PendingPhone: React.FC<IPendingPhone> = ({ phoneNumber }) => {
    const styles = useStyles();
    return (
        <Box className={styles.root}>
            {phoneNumber && <Typography className={styles.phoneNumber}>{phoneNumber}</Typography>}
            <PendingLabel />
        </Box>
    );
};

export const idColumns: ITableColumn[] = [
    {
        label: "Name",
        property: ["firstName", "lastName"],
    },
    {
        label: "Phone number",
        property: "phoneNumber",
        cell(props) {
            return (
                <PendingPhone phoneNumber={props} />
            );
        },
    },
    {
        label: "Verification Url",
        property: "url",
        align: "left",
        cell(props) {
            const url = props?.retrieveIdVerification?.verificationUrl || `https://verify-staging.autocorp.ai/id/${props?.id}`;
            return (
                <Box style={urlColumnStyles}>
                    <span>
                        {truncate(url, { "length": 48, "omission": "..." })}
                    </span>
                    <ClipboardCopyIcon copyText={url} />
                </Box>
            );
        },
    },
    {
        label: "",
        property: "more",
        align: "right",
        cell(props) {
            return (
                <PendingOptions leadId={props} />
            );
        },
    },
];