import React, { useMemo } from "react";

import { useIdContext } from "@id/context";
import { spinnerStyles } from "@id/styles";

import { Status, useIdLeads } from "@api/id";
import { PAGE_SIZE_PENDING } from "@id/constants";

import Box from "@material-ui/core/Box";

import { PendingList } from "./PendingList";
import { Spinner } from "~/components/Spinner";
import { useSearch } from "~/components/Search";

export const IdPendingDisplay: React.FC = () => {
    const { search } = useSearch();
    const { companyId, page } = useIdContext();
    const { data, error, loading, count } = useIdLeads({ companyId, status: Status.PENDING, limit: PAGE_SIZE_PENDING, offset: (page - 1) * PAGE_SIZE_PENDING, search });
    const content = useMemo(() => {
        const paging = {
            count: count,
            limit: PAGE_SIZE_PENDING,
        };
        if (error) {
            return (
                <span>{error}</span>
            );
        }

        if (loading) {
            return <Spinner style={spinnerStyles} />;
        }

        return (
            <PendingList paging={paging} data={data} />
        );
    }, [count, error, loading, data]);

    return (
        <Box
            display="flex"
            justifyContent={loading || error ? "center" : ""}
            flexDirection="column"
            flexWrap="nowrap"
            height="100%"
        >
            {content}
        </Box>
    );
};