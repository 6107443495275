import React, { useMemo } from "react";

import { ITableRow, Table } from "~/components/Table";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { idColumns } from "./columns";

import { PagingProps } from "@id/types";
import Pager from "@id/Pager";


const useStyles = makeStyles(() => ({
    contentContainer: {
        display: "flex",
        flexFlow: "row nowrap",
        height: "100%",
        overflow: "hidden",
    },
    tableContainer: {
        display: "flex",
        flexFlow: "column nowrap",
        width: "100%",
        height: "100%",
        overflow: "auto",
    },
    table: {
        minHeight: 350,
        height: "100%",
        userSelect: "none",
    },
}));

export const PendingList = ({ data, paging }: { data: any[], children?: any, paging: PagingProps }) => {
    const styles = useStyles();
    const { count, limit } = paging;

    const rows: ITableRow[] = useMemo(() => (
        data?.map((node: any) => {
            return {
                id: `${node.id}`,
                values: [
                    [node.firstName, node.lastName].filter(Boolean).join(" "),
                    node.phoneNumber,
                    node,
                    node?.id,
                ],
            } as ITableRow;
        }) || []
    ), [data]);

    const content = useMemo(() => {
        return (
            <Box className={styles.contentContainer}>
                <Box className={styles.tableContainer}>
                    <Box className={styles.table}>
                        <Table
                            columns={idColumns}
                            rows={rows}
                            loading={false}
                        />
                    </Box>
                    <Pager count={count} limit={limit} />
                </Box>
            </Box>
        );
    }, [count, limit, rows, styles.contentContainer, styles.table, styles.tableContainer]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
            height="100%"
        >
            {content}
        </Box>
    );
};