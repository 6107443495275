import React from "react";

import { useAuth } from "@api/auth";

import { createIdView, ViewComponent } from "../_lib/view";

import { IdProvider } from "@id/context";

import { isProductEnabled } from "@utils/products";

import { IdPendingDisplay } from "./_lib/IdPendingDisplay";

import { ProductType } from "@api/products";
import { Redirect } from "~/components/Redirect";

const PendingView: ViewComponent = ({ navigate, location }) => {
    const { selectedCompany } = useAuth();

    const isIdToolEnabled = isProductEnabled(selectedCompany, ProductType.IdTool);
    if (!isIdToolEnabled) {
        return <Redirect to="/id/" />;
    }

    return (
        <IdProvider >
            <IdPendingDisplay />
        </IdProvider>
    );
};

export default createIdView(PendingView, {
    title: "Pending",
    internalPad: true,
    internalScroll: true,
});